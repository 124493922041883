import {MemberAddressModel} from '../models/MemberAddress.model';
import {AddressWithContactModel} from '../models/AddressWithContact.model';
import {AddressModel} from '../models/Address.model';
import {StreetAddressModel} from '../models/StreetAddress.model';
import {ContactModel} from '../models/Contact.model';
import {getCountryByKey} from '@wix/wixstores-locale-dataset-adapter';
import {getFullSubdivisionCode} from './localeDataset.util';
import {MemberStreetAddressModel} from '../models/MemberStreetAddress.model';
import {getCommonVatId} from './vat.util';
import {ApiAddress, FullAddressContactDetails} from '@wix/ambassador-ecom-v1-checkout/types';

export const mapToAddressWithContactModel = (memberAddress: MemberAddressModel): AddressWithContactModel => {
  return new AddressWithContactModel({
    addressesServiceId: memberAddress.id,
    address: new AddressModel({
      streetAddress: isStreet(memberAddress.street)
        ? new StreetAddressModel({
            name: memberAddress.street?.name,
            number: memberAddress.street?.number,
          })
        : undefined,
      addressLine: !isStreet(memberAddress.street) ? memberAddress.addressLine1 : undefined,
      addressLine2: memberAddress.addressLine2,
      city: memberAddress.city,
      postalCode: memberAddress.zipCode,
      country: memberAddress.country ? getCountryByKey(memberAddress.country)?.shortKey : undefined,
      subdivision:
        memberAddress.subdivision && memberAddress.country
          ? getFullSubdivisionCode(memberAddress.country, memberAddress.subdivision)
          : undefined,
    }),
    contactDetails: new ContactModel({
      firstName: memberAddress.fullName.firstName,
      lastName: memberAddress.fullName.lastName,
      company: memberAddress.company,
      phone: memberAddress.phoneNumber,
      vatId: memberAddress.taxInfo ? getCommonVatId(memberAddress.taxInfo) : undefined,
    }),
  });
};

const isStreet = (street?: MemberStreetAddressModel): boolean => {
  return !!street?.name || !!street?.number;
};

export const mapContactModelToContactDetails = (contact?: ContactModel): FullAddressContactDetails => {
  return contact
    ? {
        firstName: contact.firstName ?? /* istanbul ignore next */ '',
        lastName: contact.lastName ?? /* istanbul ignore next */ '',
        ...(contact.phone ? {phone: contact.phone} : /* istanbul ignore next */ {}),
        ...(contact.company ? {company: contact.company} : /* istanbul ignore next */ {}),
        ...(contact.vatId ? /* istanbul ignore next */ {vatId: contact.vatId} : {}),
      }
    : /* istanbul ignore next */ {};
};

export const mapAddressModelToApiAddress = (address?: AddressModel): ApiAddress => {
  const streetAddress = address?.streetAddress
    ? {
        name: address.streetAddress.name ?? /* istanbul ignore next: test forms */ undefined,
        number: address.streetAddress.number ?? /* istanbul ignore next: test forms */ undefined,
      }
    : undefined;

  return address
    ? {
        country: address.country,
        city: address.city,
        ...(address.subdivision ? {subdivision: address.subdivision} : {}),
        ...(address.postalCode ? {postalCode: address.postalCode} : /* istanbul ignore next: test forms */ {}),
        ...(address.addressLine ? {addressLine: address.addressLine} : {}),
        ...(streetAddress ? {streetAddress} : {}),
        ...(address.addressLine2 ? {addressLine2: address.addressLine2} : {}),
      }
    : /* istanbul ignore next */ {};
};

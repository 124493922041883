import {AddressWithContact} from '@wix/ambassador-ecom-v1-checkout/types';
import {AddressModel} from './Address.model';
import {ContactModel} from './Contact.model';

export class AddressWithContactModel {
  public address: AddressModel;
  public contact: ContactModel;
  public addressesServiceId?: string;
  constructor(addressWithContact: AddressWithContact) {
    this.address = new AddressModel(addressWithContact.address);
    this.contact = new ContactModel(addressWithContact.contactDetails);
    this.addressesServiceId = addressWithContact.addressesServiceId ?? undefined;
  }
}

import {isCheckoutMissingCustomField} from '../utils/checkoutSettings.utils';
import {StepsManagerService} from '../services/StepsManagerService';
import {BIService} from '../services/BIService';
import {CheckoutService} from '../services/CheckoutService';
import {CheckoutStep, StepId, StepsManagerStoreProps, StepState} from '../../types/app.types';
import {CheckoutSettingsService} from '../services/CheckoutSettingsService';
import {MemberService} from '../services/MemberService';

export class StepsManagerStore {
  private readonly stepsManagerService: StepsManagerService;
  private readonly biService: BIService;
  private readonly checkoutService: CheckoutService;
  private readonly checkoutSettingsService: CheckoutSettingsService;
  private readonly memberService: MemberService;
  private readonly updateComponent: () => void;
  private readonly isSSR: boolean;

  constructor({
    stepsManagerService,
    biService,
    checkoutService,
    checkoutSettingsService,
    memberService,
    updateComponent,
    isSSR,
  }: {
    stepsManagerService: StepsManagerService;
    biService: BIService;
    checkoutService: CheckoutService;
    checkoutSettingsService: CheckoutSettingsService;
    memberService: MemberService;
    updateComponent: () => void;
    isSSR: boolean;
  }) {
    this.stepsManagerService = stepsManagerService;
    this.biService = biService;
    this.checkoutService = checkoutService;
    this.checkoutSettingsService = checkoutSettingsService;
    this.memberService = memberService;
    this.updateComponent = updateComponent;
    this.isSSR = isSSR;
  }

  private readonly sendEditStepClickedBIEvent = (stepId: StepId): void => {
    const previousStepName = this.stepsManagerService.getPreviousStep();
    this.biService.sendEditStepClicked(this.checkoutService.checkout, stepId, previousStepName);
  };

  public initStepsManagerService = (): void => {
    this.stepsManagerService.stepsList = this.stepsManagerService.getSteps(this.checkoutService.checkout);
    this.calculateInitialStep();
  };

  private readonly calculateInitialStep = (): void => {
    let initialStepIndex = 0;
    const isMissingAMandatoryField = this.validateManadtoryCustomerDetailsFields();

    if (this.memberService.isMember() && !isMissingAMandatoryField) {
      initialStepIndex++;
    }

    this.updateStepOnStage(initialStepIndex, this.stepsManagerService.stepsList[initialStepIndex]);
  };

  private readonly openStep = (stepIndex: number): void => {
    const stepId = this.stepsManagerService.stepsList[stepIndex];
    this.updateStepOnStage(stepIndex, stepId);
    this.sendEditStepClickedBIEvent(stepId);
  };

  private readonly validateManadtoryCustomerDetailsFields = (): boolean => {
    const {checkoutSettings} = this.checkoutSettingsService;
    const {checkout} = this.checkoutService;

    const isMissingFirstName = Boolean(!checkout.shippingDestination?.contact.firstName);
    const isMissingLastName = Boolean(!checkout.shippingDestination?.contact.lastName);
    const isMissingPhone = Boolean(checkoutSettings.phone?.mandatory && !checkout.shippingDestination?.contact?.phone);

    const isMissingCustomField = isCheckoutMissingCustomField(checkoutSettings.customField, checkout.customField);
    const isMissingCompanyName = Boolean(
      checkoutSettings.companyName?.mandatory && !checkout.shippingDestination?.contact.company
    );

    return isMissingFirstName || isMissingLastName || isMissingPhone || isMissingCustomField || isMissingCompanyName;
  };

  private readonly updateStepOnStage = (stepIndex: number, stepId: StepId): void => {
    this.sendStageExpandedBIEvent(stepId);
    this.stepsManagerService.updateStepOnStage(stepIndex, stepId);
    this.updateComponent();
  };

  private readonly shouldDisplayExpressCheckout = (): boolean => {
    return (
      !this.isSSR &&
      !this.checkoutService.checkout.hasSubscriptionItems &&
      this.stepsManagerService.getActiveStep().stepIndex === 0
    );
  };

  private readonly sendStageExpandedBIEvent = (stepId: StepId): void => {
    this.biService.sendStageExpanded(
      this.checkoutService.checkout,
      stepId,
      !this.stepsManagerService.isStepInQueue(stepId)
    );
  };

  private readonly getCheckoutSteps = (): CheckoutStep[] => {
    const activeStepIndex = this.stepsManagerService.getActiveStep().stepIndex;
    const steps = this.stepsManagerService.stepsList;
    return steps.map((step, index) => {
      let state;
      const isLastStep = index === steps.length - 1;
      if (activeStepIndex < index) {
        state = StepState.EMPTY;
      } else {
        state = activeStepIndex === index || isLastStep ? StepState.OPEN : StepState.COLLAPSED;
      }

      return {
        id: step,
        state,
      };
    });
  };

  private readonly goToNewlyAddedStep = (
    previousSteps: StepId[],
    newSteps: StepId[],
    newIndexOfActiveStep: number
  ): void => {
    const activeStep = this.stepsManagerService.getActiveStep();
    let newStepIndex = newIndexOfActiveStep;
    let newStepId = activeStep.stepId;
    let i = 0;

    while (i < previousSteps.length) {
      if (previousSteps[i] !== newSteps[i]) {
        newStepIndex = i;
        newStepId = newSteps[i];
        break;
      }

      i++;
    }

    this.updateStepOnStage(newStepIndex, newStepId);
  };

  public toProps(): StepsManagerStoreProps {
    const previousSteps = [...this.stepsManagerService.stepsList];
    const newSteps = this.stepsManagerService.getSteps(this.checkoutService.checkout);
    this.stepsManagerService.stepsList = newSteps;
    const activeStep = this.stepsManagerService.getActiveStep();
    const newIndexOfActiveStep = newSteps.indexOf(activeStep.stepId);

    if (newIndexOfActiveStep === -1) {
      this.updateStepOnStage(activeStep.stepIndex, newSteps[activeStep.stepIndex]);
    } else if (newIndexOfActiveStep < activeStep.stepIndex) {
      this.updateStepOnStage(newIndexOfActiveStep, activeStep.stepId);
    } else if (newIndexOfActiveStep > activeStep.stepIndex) {
      this.goToNewlyAddedStep(previousSteps, newSteps, newIndexOfActiveStep);
    }

    return {
      sendStageExpandedBIEvent: this.sendStageExpandedBIEvent,
      updateStepOnStage: this.updateStepOnStage,
      openStep: this.openStep,
      sendEditStepClickedBIEvent: this.sendEditStepClickedBIEvent,
      shouldDisplayExpressCheckout: this.shouldDisplayExpressCheckout(),
      activeStep: this.stepsManagerService.getActiveStep(),
      stepsList: this.getCheckoutSteps(),
    };
  }
}

import {CommonVatId, CommonVatType} from '@wix/ambassador-ecom-v1-checkout/types';

export const getCommonVatId = ({id, type}: {id?: string; type?: string}): CommonVatId | undefined => {
  if (id) {
    return {
      id,
      type: getCommonVatType(type),
    };
  }
  return undefined;
};

const getCommonVatType = (type?: string): CommonVatType => {
  switch (type) {
    /* istanbul ignore next */ case 'CPF':
      return /* istanbul ignore next */ CommonVatType.CPF;
    case 'CNPJ':
      return /* istanbul ignore next */ CommonVatType.CNPJ;
    /* istanbul ignore next */ default:
      return /* istanbul ignore next */ CommonVatType.UNSPECIFIED;
  }
};
